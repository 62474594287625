import React from "react"
import { Link } from "gatsby"
//import Header from "../components/header"

export default () => (
    <div>
        <div>
            <h1 class="title">あおまるの日々</h1>
            <p class="undertitle">車輪２個の乗り物が好きなエンジニアです。</p>
        </div>
        <Link to="/about/index.html"><img src="https://cdn-ak.f.st-hatena.com/images/fotolife/n/nyakkiman/20190618/20190618234255.jpg" alt="" width="1200" height="675" style={{marginTop: 30}} /></Link>
    </div>
)
